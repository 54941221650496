import React from "react";

const ModalAfterContactSubmit = () => {
  return (
    <div className="slideshow">
      <figure>
        {/* Project Details Starts */}
        <figcaption>
          <h3>Thank you!</h3>
          <div className="row open-sans-font">
            <div className="col-12">
              <p className="text-center"><i className="fa fa-check"></i> Your message has been successfully sent <br/> I will contact you as soon as possible </p>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </figcaption>
        {/* Project Details Ends */}
      </figure>
    </div>
    //  End .slideshow
  );
};

export default ModalAfterContactSubmit;
