import React, { useEffect } from "react"; // Include Library React Effect
import Routes from "./router/Routes"; // Include Library Routes
import ScrollToTop from "./components/ScrollToTop"; // Include Library ScrollToTop
import AOS from "aos"; // Include Library AOS For Animation
import "aos/dist/aos.css"; // Include Library AOS Css
import AnimatedCursor from "react-animated-cursor"; // Include Library Cursor

const App = () => {
  // this for animation
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="255, 160, 1"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />
      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
