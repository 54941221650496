import React from "react";

const educationContent = [
  {
    year: "2013-2015",
    degree: "First programming experience",
    institute: "Freelance",
    details: `First steps in web design, programming and cross-browser application development`,
  },
  {
    year: "2013",
    degree: "MASTER DEGREE",
    institute: "VOLGOGRAD STATE UNIVERSITY",
    details: `Successfully graduated from Volgograd State University with a degree in mathematician-programmer`,
  },
  {
    year: "2008",
    degree: "STUDENT",
    institute: "VOLGOGRAD STATE UNIVERSITY",
    details: `Entered the Department of Mathematical Support and Administration of Information Systems`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
