import React from 'react'; // include React
import ReactDOM from 'react-dom/client'; // include ReactDom
import './assets/scss/main.scss'; // include Styles
import App from './App'; // include App
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root')); // create ReactDom Object

// render ReactDom
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
