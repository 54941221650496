import React, {useState} from "react";

const hero_description = () => {
  return (
    <>
      <p>
        Welcome! My name is Dmitry. I am Full Stack Web Developer. Development of websites of any complexity. Developing websites of any type. From landing pages to multifunctional online portals. I also offer comprehensive Internet marketing.
      </p>
      <p>
        Create a website of any type and complexity. An integrated approach. I offer a full package of services, starting with the development of a business idea and ending with the maintenance of a website. Long-term results and constant growth are important for me.
      </p>
      <p>
        First of all, my goal is the implementation of multifunctional projects for the benefit of society and the formation of good taste among users. There is only one chance to make a good impression on the user. I make a decision whether I like the site or not in just 10 seconds. Therefore, the convenience and quality of the resource directly affect the number of online sales. It is important to maintain the attention of the audience, so I carefully think over the structure of the site, design and content. As a result, the site is able to significantly increase the flow of customers.
      </p>
      <p>
        I provide a full range of website creation services: design, development of mobile and adaptive design, integration with Wordpress, implementation of various external systems.
      </p>
      <p>
        What is included in the integrated approach:
      </p>
      <ul>
        <li>A project that will solve the set business tasks, taking into account the features of the niche and user experience</li>
        <li>Adaptive design. We create truly unique designs at the global level, and not at the level of other sites in the top of the search results</li>
        <li>Advanced features: stable operation with a large amount of information, the ability to connect additional services and transfer data between different systems</li>
        <li>Website promotion: increase conversion competent optimization for the search engines Yahoo and Google, generation of leads.</li>
        <li>Functionality of any complexity</li>
        <li>All my sites are adaptive, with a built-in algorithm that recognizes the device and adjusts the necessary design to it in a precisely calculated configuration</li>
      </ul>
      <p>
        Today, the website largely determines the status of the company, the presence of its own multifunctional website has become a necessity for everyone.
      </p>
      <p>
        I will find the right solutions for you and the users. <br />
        Implement the most daring and complex ideas with me!
      </p>
    </>
  );
};

export default hero_description;
